import request from "@/utils/request";
export function appLogin(data) {
  return request({
    url: "/Interflow/login",
    method: "post",
    data,
  });
}
export function uploadImg(data) {
  return request({
    url: "/Questionnaire/upload",
    method: "post",
    data,
  });
}
export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data,
  });
}
export function getUser(data) {
  return request({
    url: "/index/config",
    method: "get",
    params: data,
  });
}
export function logOut(data) {
  return request({
    url: "/user/logout",
    method: "post",
    data,
  });
}
export function register(data) {
  return request({
    url: "/user/mobileregister",
    method: "post",
    data,
  });
}
export function resetpwd(data) {
  return request({
    url: "/user/resetpwd",
    method: "post",
    data,
  });
}
export function sendCode(data) {
  return request({
    url: "/sms/send",
    method: "post",
    data,
  });
}
export function getIndex(data) {
  return request({
    url: "/index/index",
    method: "get",
    params: data,
  });
}
export function getAnswer(data) {
  return request({
    url: "/questionnaire/effect",
    method: "get",
    params: data,
  });
}
export function getLog(data) {
  return request({
    url: "/questionnaire/log",
    method: "get",
    params: data,
  });
}
export function getOrder(data) {
  return request({
    url: "/order/index",
    method: "get",
    params: data,
  });
}
export function getQuestion(data) {
  return request({
    url: "/questionnaire/items",
    method: "get",
    params: data,
  });
}
export function answerSubmit(data) {
  return request({
    url: "/questionnaire/submit",
    method: "post",
    data,
  });
}
export function changeUser(data) {
  return request({
    url: "/user/profile",
    method: "post",
    data,
  });
}
export function Upload(data) {
  return request({
    url: "/common/upload",
    method: "post",
    data,
  });
}
export function Pay(data) {
  return request({
    url: "/pay/scanPay",
    method: "post",
    data,
  });
}
export function payResult(data) {
  return request({
    url: "/order/getPayStatus",
    method: "post",
    data,
  });
}
export function aliPay(data) {
  return request({
    url: "/pay/prepay",
    method: "post",
    data,
  });
}
