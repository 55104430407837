<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {},

  methods: {},
};
</script>

<style lang="less" scoped>
@import url("./assets/css/clear.css");
</style>
