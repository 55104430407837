import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import "./assets/css/clear.css";
import Vant from "vant";
import { Toast } from "vant";
import "vant/lib/index.css";
import VueCanvasPoster from "vue-canvas-poster";
Vue.use(VueCanvasPoster);
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Toast);

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// new Vue({
//
//   components: { App },
//   template: '<App/>'
// })
