import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    indexInfo: {},
  },
  getters: {
    Logo(state) {
      return state.logo;
    },
  },
  mutations: {
    setUserInfo(state, msg) {
      state.userInfo = msg;
    },
    setLogo(state, msg) {
      console.log(msg);
      state.logo = msg;
    },
    setIndex(state, msg) {
      console.log(9999);
      state.indexInfo = msg;
      console.log(state.indexInfo);
    },
    setName(state, msg) {
      state.userInfo.userinfo.nickname = msg;
    },
    setAvatar(state, msg) {
      state.userInfo.userinfo.avatar = msg;
    },
  },
  actions: {},
  modules: {},
});
