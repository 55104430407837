import axios from "axios";
import store from "@/store";
import router from "@/router";

import { Message } from "element-ui";
const service = axios.create({
  baseURL: "https://geniusapi.tikvah.cn/api", // url = base url + request url
  timeout: 5000, // request timeout
});
service.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;application/json;charset=UTF-8"; //配置请求头

service.interceptors.request.use(
  (config) => {
    if (
      config.url == "/common/upload" ||
      config.url == "/Questionnaire/upload"
    ) {
      config.headers["Content-Type"] = "form-data";
    }
    config.headers.token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code == "4001") {
      localStorage.removeItem("token");
      store.commit("setUserInfo", {});
      router.push("./Login");
    }
    return res;
  },
  (error) => {
    console.log("err" + error); // for debug

    // Message({
    //   message: error.response.data.msg,
    //   type: "error",
    //   duration: 2 * 1000,
    // });

    return Promise.reject(error);
  }
);

export default service;
