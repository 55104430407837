import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { getUser } from "@/api";
Vue.use(Router);
const routesP = [
  {
    path: "/",
    name: "PC",
    redirect: "Index",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/pc/PC.vue"),
    children: [
      {
        path: "/Index",
        name: "PcIndex",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/pc/index.vue"),
      },
      {
        path: "/About",
        name: "PcAbout",
        component: () => import("@/views/pc/about.vue"),
      },
      {
        path: "/Answer",
        name: "PcAnswer",
        component: () => import("@/views/pc/answer.vue"),
      },
      {
        path: "/UsersInfo",
        name: "PcUsersInfo",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/pc/usersInfo.vue"),
      },
      {
        path: "/Users",
        name: "PcUsers",
        redirect: "Answerorder",
        component: () => import("@/views/pc/users.vue"),
        children: [
          {
            path: "/Answerorder",
            name: "PcAnswerorder",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/pc/answerorder.vue"
              ),
          },
          {
            path: "/Paymentorder",
            name: "PcPaymentorder",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/pc/paymentorder.vue"
              ),
          },
          {
            path: "/Payment",
            name: "PcPayment",
            component: () =>
              import(/* webpackChunkName: "about" */ "@/views/pc/payment.vue"),
          },
          {
            path: "/coupon",
            name: "PcCoupon",
            component: () =>
              import(/* webpackChunkName: "about" */ "@/views/pc/coupon.vue"),
          },
        ],
      },
      {
        path: "/Contactus",
        name: "PcContactus",
        component: () => import("@/views/pc/contactus.vue"),
      },
    ],
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/pc/login.vue"),
  },
  { path: "*", redirect: "/Login" },
];

const routesM = [
  {
    path: "/",
    name: "IPhone",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/Mobile.vue"),
    redirect: "Index",
    children: [
      {
        path: "/Index",
        name: "MoIndex",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/mobile/index.vue"),
      },
      {
        path: "/About",
        name: "MoAbout",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/mobile/about.vue"),
      },
      {
        path: "/Contactus",
        name: "MoContactus",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/mobile/contactus.vue"
          ),
      },
    ],
  },

  {
    path: "/Login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/Login.vue"),
  },
  {
    path: "/Users",
    name: "MoUsers",
    redirect: "Answerorder",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/users.vue"),
    children: [
      {
        path: "/Answerorder",
        name: "MoAnswerorder",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/mobile/answerorder.vue"
          ),
      },
      {
        path: "/paymentorder",
        name: "Mopaymentorder",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/mobile/paymentorder.vue"
          ),
      },
      {
        path: "/coupon",
        name: "MoCoupon",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/mobile/coupon.vue"),
      },
    ],
  },
  {
    path: "/ChangeUsers",
    name: "MoChangeUsers",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/ChangeUsers.vue"),
    children: [],
  },
  {
    path: "/ChangeMobile",
    name: "MoChangeMobile",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/ChangeMobile.vue"),
  },

  {
    path: "/NewMobile",
    name: "MoNewMobile",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/NewMobile.vue"),
  },
  {
    path: "/Code",
    name: "MoCode",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/Code.vue"),
  },
  {
    path: "/ChangeEmail",
    name: "MoChangeEmail",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/ChangeEmail.vue"),
  },
  {
    path: "/Answer",
    name: "MoAnswer",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/answer.vue"),
  },
  {
    path: "/AnswerInfo",
    name: "MoAnswerInfo",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/answerInfo.vue"),
  },
  {
    path: "/Payment",
    name: "MoPayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/payment.vue"),
  },
];

var routes = [];
var os = (function () {
  var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc,
  };
})();
console.log(os);
if (os.isTablet) {
  routes = routesP;
} else if (os.isAndroid || os.isPhone) {
  routes = routesM;
} else {
  routes = routesP;
}

const router = new Router({
  routes,

  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
  mode: "hash",
});
router.beforeEach((to, from, next) => {
  const hasToken = localStorage.getItem("token");
  const hasUser = store.state.userInfo;
  // if (hasUser.userinfo?.nickname) {
  //   next();
  // } else {
  //   getUser().then((res) => {
  //     if (res.code == 1) {
  //       console.log(res);
  //       store.commit("setUserInfo", res.data);
  //       next();
  //     }
  //   });
  // }
  if (hasToken) {
    if (to.path === "/Login") {
      next({ path: "/" });
    } else {
      if (hasUser.userinfo?.nickname) {
        next();
      } else {
        getUser().then((res) => {
          if (res.code == 1) {
            console.log(res);
            store.commit("setUserInfo", res.data);
            document.title = res.data.name;
            next();
          }
        });
      }
    }
  } else {
    if (to.path == "/Index" || to.path == "/Login") {
      if (hasUser.userinfo?.nickname) {
        next();
      } else {
        getUser().then((res) => {
          if (res.code == 1) {
            console.log(res);
            store.commit("setUserInfo", res.data);
            next();
          }
        });
      }
    } else {
      next("/Login");
    }
  }
});
router.afterEach((to, from) => {
  // console.log(to);
});
export default router;
